<template>
    <v-container fluid class="p-4" style="padding-bottom:80px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-col>
      </v-row>
      <v-row class="mt-5">
            <v-col cols="12" xs="12" sm="12" md="2">
                <h2 class="blue-lcd text-primary pl-4" style="font-weight:600;">Merchandiser</h2>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="8">

            </v-col>
            <v-col cols="12" xs="12" sm="12" md="2">
                <v-btn block color="primary" class="text-white rounded-l mr-4" height="50px" @click="addMerchandiser()">
                    <v-icon>mdi-plus</v-icon> Merchandiser Baru
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <!-- <v-col cols="12">
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="sales"
                                    :items="saleses"
                                    item-value="salesman_id"
                                    item-text="salesman_name"
                                    label="Merchandiser"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-btn
                                    class="mr-2 mt-1"
                                    color="error"
                                    elevation="2"
                                    large
                                    @click="search()"
                                >Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
            </v-col> -->
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="merchandisers"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.active_flag`]="{ item }">
                                        <v-switch
                                            v-model="item.active_flag"
                                            inset
                                            color="primary"
                                            class="pt-1"
                                            value="Y"
                                            @change="changeActiveFlag(item)"
                                        ></v-switch>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" color="primary" @click="showItem(item)" v-on="on">
                                                    mdi-application-edit-outline
                                                </v-icon>
                                            </template>
                                            <span>Edit</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog class="rounded-xl" v-model="dialog" max-width="1300px" persistent transition="dialog-bottom-transition" style="background: #fff;">                
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="p-0" style="padding:0px !important">
                                <v-card class="rounded-xl elevation-12" style="background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;">
                                    <v-card-title style="font-weight:600">
                                        <v-row align="center" justify="center" style="margin-top: -16px;">
                                            <v-col cols="12" xs="12" sm="6" md="4" style="background: #053d76;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;">
                                                <h4 class="text-white text-center" style="font-weight:700">
                                                    Merchandiser
                                                </h4>
                                                <h5 class="text-white text-center">Form Isian Master Merchandiser</h5>
                                            </v-col>
                                        </v-row>
                                    </v-card-title>
                                    <v-card-text>
                                        <validation-observer
                                            ref="observer"
                                            v-slot="{ invalid }"
                                        >
                                            <form @submit.prevent="submit">
                                                <v-container fluid>
                                                    <v-row class="mt-lg-5">
                                                        <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">User Login</h6>
                                                            <v-text-field
                                                                solo
                                                                label="User Login"
                                                                persistent-hint
                                                                required
                                                                v-model="username"
                                                                class="ma-0 pa-0 border-12"
                                                                disabled
                                                            >
                                                            </v-text-field>     
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">First Name</h6>
                                                            <validation-provider
                                                            v-slot="{ errors }"
                                                            name="first_name"
                                                            rules="required"
                                                            >
                                                                <v-text-field
                                                                    solo
                                                                    label="First Name"
                                                                    persistent-hint
                                                                    required
                                                                    v-model="first_name"
                                                                    class="ma-0 pa-0 border-12"
                                                                    :error-messages="errors"
                                                                    @keyup="getUsername()"
                                                                >
                                                                </v-text-field>  
                                                            </validation-provider>   
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">Last Name</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="last_name"
                                                                rules="required"
                                                            >
                                                                <v-text-field
                                                                    solo
                                                                    label="Last Name"
                                                                    persistent-hint
                                                                    required
                                                                    v-model="last_name"
                                                                    class="ma-0 pa-0 border-12"
                                                                    :error-messages="errors"
                                                                >
                                                                </v-text-field>  
                                                            </validation-provider>   
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">No KTP</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="no_ktp"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                solo
                                                                label="No KTP"
                                                                persistent-hint
                                                                required
                                                                v-model="no_ktp"
                                                                class="ma-0 pa-0 border-12"
                                                                :error-messages="errors"
                                                            >
                                                            </v-text-field> 
                                                            </validation-provider>    
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="2" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">Posisi</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="posisi"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                solo
                                                                label="Posisi"
                                                                persistent-hint
                                                                required
                                                                v-model="posisi"
                                                                class="ma-0 pa-0 border-12"
                                                                :error-messages="errors"
                                                            >
                                                            </v-text-field>    
                                                            </validation-provider> 
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">Alamat</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="address"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                solo
                                                                label="Alamat"
                                                                persistent-hint
                                                                required
                                                                v-model="address"
                                                                class="ma-0 pa-0 border-12"
                                                                :error-messages="errors"
                                                            >
                                                            </v-text-field> 
                                                            </validation-provider>    
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="1" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">RT</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="RT"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                solo
                                                                label="RT"
                                                                persistent-hint
                                                                required
                                                                v-model="rt"
                                                                class="ma-0 pa-0 border-12"
                                                                :error-messages="errors"
                                                            >
                                                            </v-text-field>    
                                                            </validation-provider> 
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="1" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">RW</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="RW"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                solo
                                                                label="RW"
                                                                persistent-hint
                                                                required
                                                                v-model="rw"
                                                                class="ma-0 pa-0 border-12"
                                                                :error-messages="errors"
                                                            >
                                                            </v-text-field>     
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="2" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">Kelurahan</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="kelurahan"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                solo
                                                                label="Kelurahan"
                                                                persistent-hint
                                                                required
                                                                v-model="kelurahan"
                                                                class="ma-0 pa-0 border-12"
                                                                :error-messages="errors"
                                                            >
                                                            </v-text-field> 
                                                            </validation-provider>    
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="2" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">Kecamatan</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="kecamatan"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                solo
                                                                label="Kecamatan"
                                                                persistent-hint
                                                                required
                                                                v-model="kecamatan"
                                                                :error-messages="errors"
                                                                class="ma-0 pa-0 border-12"
                                                            >
                                                            </v-text-field>   
                                                            </validation-provider>  
                                                        </v-col> 
                                                        <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">Kota</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="city"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                solo
                                                                label="Kota"
                                                                persistent-hint
                                                                required
                                                                v-model="city"
                                                                class="ma-0 pa-0 border-12"
                                                                :error-messages="errors"
                                                            >
                                                            </v-text-field>  
                                                            </validation-provider>   
                                                        </v-col>  
                                                        <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">Provinsi</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="province"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                solo
                                                                label="Provinsi"
                                                                persistent-hint
                                                                required
                                                                v-model="province"
                                                                :error-messages="errors"
                                                                class="ma-0 pa-0 border-12"
                                                            >
                                                            </v-text-field>    
                                                            </validation-provider> 
                                                        </v-col> 
                                                        <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">No HP</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="no_hp"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                solo
                                                                label="No HP"
                                                                persistent-hint
                                                                required
                                                                v-model="no_hp"
                                                                class="ma-0 pa-0 border-12"
                                                                :error-messages="errors"
                                                            >
                                                            </v-text-field>   
                                                            </validation-provider>  
                                                        </v-col> 
                                                        <v-col cols="12" xs="12" sm="6" md="3" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">Email</h6>
                                                            <validation-provider
                                                                v-slot="{ errors }"
                                                                name="email"
                                                                rules="required"
                                                            >
                                                            <v-text-field
                                                                type="email"
                                                                solo
                                                                label="Email"
                                                                persistent-hint
                                                                required
                                                                v-model="email"
                                                                class="ma-0 pa-0 border-12"
                                                                :error-messages="errors"
                                                            >
                                                            </v-text-field>   
                                                            </validation-provider>  
                                                        </v-col>                                                
                                                    </v-row>
                                                    <v-row class="mt-3">
                                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                                            <h6 class="blue-lcd">Status</h6>
                                                            <div class="d-flex mt-0 pt-0">
                                                                <validation-provider
                                                                    v-slot="{ errors }"
                                                                    name="active_flag"
                                                                    rules="required"
                                                                >
                                                                <v-checkbox
                                                                    v-model="active_flag"
                                                                    label="Active"
                                                                    value="Y"
                                                                    class="mr-8"
                                                                    :error-messages="errors"
                                                                ></v-checkbox>
                                                                </validation-provider>
                                                                <validation-provider
                                                                    v-slot="{ errors }"
                                                                    name="active_flag"
                                                                    rules="required"
                                                                >
                                                                <v-checkbox
                                                                    v-model="active_flag"
                                                                    label="Non Active"
                                                                    value="N"
                                                                    class="mr-8"
                                                                    :error-messages="errors"
                                                                ></v-checkbox>
                                                                </validation-provider>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row justify="center" class="mt-lg-12">
                                                        <v-col cols="12" xs="12" sm="6" md="2">
                                                            <v-btn block class="rounded-l p-4 text-h6" color="#fff" @click="dialog = false">Batal</v-btn>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="6" md="2">
                                                            <v-btn block class="rounded-l text-white p-4 text-h6" color="#003871" :disabled="invalid" type="submit">Submit</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </form>
                                        </validation-observer>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Merchandiser Activity',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Master Merchandiser',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            saleses: [],
            sales: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            merchandisers: [],
            headers: [
                { text: 'ID MD', value: 'md_id' },
                { text: 'User Login', value: 'username' },
                { text: 'First Name', value: 'first_name' },
                { text: 'Last Name', value: 'last_name' },
                { text: 'No KTP', value: 'no_ktp' },
                { text: 'Email', value: 'email' },
                { text: 'No HP', value: 'no_hp' },
                { text: 'Status', value: 'active_flag' },
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            pagination: 1,
            dialog: false,
            first_name: '',
            last_name: '',
            no_ktp: '',
            address: '',
            posisi: '',
            active_flag: '',
            rt: '',
            rw:'',
            kelurahan: '',
            kecamatan: '',
            city: '',
            province: '',
            no_hp: '',
            email:'',
            username: '',
            detail: null
        }
    },
    mounted(){
    },
    methods:{
        clear(){
            this.first_name = ''
            this.last_name = ''
            this.no_ktp = ''
            this.posisi = ''
            this.address = ''
            this.rt = ''
            this.rw = ''
            this.kelurahan = ''
            this.kecamatan = ''
            this.city = ''
            this.province = ''
            this.no_hp = ''
            this.email = ''
            this.active_flag = ''
            this.username = ''
        },
        addMerchandiser(){
            this.detail = ''
            this.dialog = true
            this.clear()
        },
        getUsername(){
            var name = this.first_name + '_md_sr'
            
            this.username = name.toLowerCase();
        },
        async getData(page = 1, itemsPerPage = 10){
            this.loading = true
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master?page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.merchandisers = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        },
        showItem(item){
            this.detail = item
            this.first_name = item.first_name
            this.last_name = item.last_name
            this.no_ktp = item.no_ktp
            this.posisi = item.posisi
            this.address = item.address
            this.rt = item.rt
            this.rw = item.rw
            this.kelurahan = item.kel
            this.kecamatan = item.kec
            this.city = item.kota
            this.province = item.propinsi
            this.no_hp = item.no_hp
            this.email = item.email
            this.active_flag = item.active_flag
            this.username = item.username

            this.dialog = true

        },
        async changeActiveFlag(item){
            console.log(item);

            this.loading = true

            if (item.active_flag == null) {
                var status = "N"
            } else{
                var status = "Y"
            } 

            await axios.post(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master/status`, {
                'md_id': item.md_id,
                'active_flag' : status
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading = false

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully update Status",
                    visible: true
                };

                this.getData(1,10)
            }).catch(err => {
                this.loading = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submit(){
            this.loading = true

            if (this.detail) {
                await axios.post(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master/update`, {
                    'md_id': this.detail.md_id,
                    'first_name': this.first_name,
                    'last_name': this.last_name,
                    'no_ktp': this.no_ktp,
                    'posisi': this.posisi,
                    'address': this.address,
                    'rt': this.rt,
                    'rw': this.rw,
                    'kel': this.kelurahan ? this.kelurahan : " ",
                    'kec' : this.kecamatan ? this.kecamatan : " ",
                    'kota' : this.city ? this.city : " ",
                    'propinsi' : this.province,
                    'no_hp' : this.no_hp,
                    'email' : this.email,
                    'active_flag' : this.active_flag
                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                }).then(res => {
                    this.loading = false
                    this.dialog = false
                    this.detail = ''
                    this.clear()

                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: "Successfully Update MD",
                        visible: true
                    };

                    this.getData(1,10)
                }).catch(err => {
                    this.loading = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            } else {
                await axios.post(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master/store`, {
                    'first_name': this.first_name,
                    'last_name': this.last_name,
                    'no_ktp': this.no_ktp,
                    'posisi': this.posisi,
                    'address': this.address,
                    'rt': this.rt,
                    'rw': this.rw,
                    'kel': this.kelurahan ? this.kelurahan : " ",
                    'kec' : this.kecamatan ? this.kecamatan : " ",
                    'kota' : this.city ? this.city : " ",
                    'propinsi' : this.province,
                    'no_hp' : this.no_hp,
                    'email' : this.email,
                    'active_flag' : this.active_flag,
                    'username' : this.username
                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                }).then(res => {
                    this.loading = false
                    this.dialog = false
                    this.clear()

                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: "Successfully Add MD",
                        visible: true
                    };

                    this.getData(1,10)
                }).catch(err => {
                    this.loading = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            } 
        }
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                this.getData(page, itemsPerPage)
            },
        deep: true
        },
    }
}
</script>